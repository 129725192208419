import { List } from '@mui/material'
import { useAuthentication } from '@trr/app-shell-data'
import { useGetActivitiesQuery, useGetRecordedActivititesQuery } from 'api'

import { Activity, RecordedActivity } from './components'

const Activities = () => {
  const { idp, userRoles } = useAuthentication()
  const isTIOAvslutad = userRoles.includes('TIOAvslutad')
  const isClient = idp === 'klient' ? true : false
  const MAX_ITEMS = 3

  const { data: activityData, isSuccess: activitySuccess } =
    useGetActivitiesQuery(isClient, { skip: isTIOAvslutad })

  const skipRecordedActivitiesIfFull =
    (!activitySuccess && !isTIOAvslutad) ||
    (activitySuccess && activityData.totalCount >= MAX_ITEMS)

  const recordedActivitesToTake =
    (activityData?.items.length && MAX_ITEMS - activityData.items.length) ??
    MAX_ITEMS

  const { data: recordingData } = useGetRecordedActivititesQuery(
    { isClient, take: recordedActivitesToTake },
    {
      skip: skipRecordedActivitiesIfFull,
    }
  )
  return (
    <List>
      {activityData?.items.map((activity, index) => (
        <Activity
          isLast={index === MAX_ITEMS - 1}
          activity={activity}
          key={activity.nextActivityRoundId}
        />
      ))}
      {recordingData?.webinars.map((recording, index) => (
        <RecordedActivity
          isLast={index === recordingData.webinars.length - 1}
          recording={recording}
          key={recording.id}
        />
      ))}
    </List>
  )
}

export default Activities
