const parseYoutubeId = (url: string): string => {
  if (url && url.length > 0 && typeof url === 'string') {
    let extracted
    if (url.includes('v=')) {
      extracted = url
        .substring(url.lastIndexOf('v=') + 2, url.length)
        .split('&')[0]

      if (/[a-zA-Z0-9_-]{11}/.exec(extracted)) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return extracted ?? ''
      }
    } else {
      extracted = url.split('/').join('&').split('&')
      return (
        extracted.filter(
          (segment: string) => /[a-zA-Z0-9_-]{11}/.exec(segment) && segment
        )[0] ?? ''
      )
    }
  }
  return ''
}

export default parseYoutubeId
