/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { getAppConfig } from '@trr/app-shell-data'
import {
  ActivityImageCategoryKeys,
  getActivityImage,
  ImageCategories,
  imageCategorization,
} from 'utils'

export const buildImageUrl = (imageUrl: string): string => {
  if (imageUrl.startsWith('http')) {
    return imageUrl
  }
  return `${getAppConfig().COMMON.MEDIA_URL}${
    imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`
  }`
}

const mapAreaOfInterestToCategory = (
  areaOfInterest: string,
  isCustomer: boolean,
  cats: ImageCategories
): ActivityImageCategoryKeys => {
  if (isCustomer) {
    return 'customer'
  }
  const key =
    Object.entries(cats).find((i) => i[1].includes(areaOfInterest))?.[0] ??
    'generic'
  return key as ActivityImageCategoryKeys
}
export const generateImage = (
  slot: number,
  areaOfInterest: string,
  isCustomer: boolean
): string => {
  const mappedAreaOfInterest = mapAreaOfInterestToCategory(
    areaOfInterest,
    isCustomer,
    imageCategorization
  )
  const pixels = 80
  return getActivityImage(mappedAreaOfInterest, slot, pixels)
}
