import {
  createApi,
  fetchBaseQuery,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'
import {
  YoutubeResponse,
  ActivitiesResponse,
  RecordedActivitiesApiResponse,
} from 'types'
import { generateImage, parseYoutubeId } from 'utils'

const {
  COMMON: { PUBLIC_WEB_API_URL },
} = getAppConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: PUBLIC_WEB_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const viewFromType = {
  client: 'anstalld',
  customer: 'kund',
} as const

export const viewTypeParam = (isClient: boolean) =>
  `view=${isClient ? viewFromType.client : viewFromType.customer}`

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getActivities: builder.query<ActivitiesResponse, boolean>({
      query: (isClient) => {
        return `/aktivitetsbokningar/activities?&pageSize=3&${viewTypeParam(
          isClient
        )}`
      },
      transformResponse: (response: ActivitiesResponse, _, isClient) => {
        const result = response.items
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          .filter((activity) => activity)
          .slice(0, 3)
          .map((i) => {
            if (i.imageUrl) return i
            const randomNumber = i.nextActivityRoundId.replace(/\D/g, '')
            const slot = Number(randomNumber[randomNumber.length - 1])
            return {
              ...i,
              imageUrl: generateImage(slot, i.areasOfInterest[0], isClient),
            }
          })
        return { ...response, items: result }
      },
    }),
    getRecordedActivitites: builder.query<
      RecordedActivitiesApiResponse,
      { isClient: boolean; take: number }
    >({
      queryFn: async (
        { isClient, take },
        _queryApi,
        _extraOptions,
        fetchWithMetaData
      ) => {
        const fetchEpiVideos = await fetchWithMetaData(
          `content/webinars?take=${take.toString()}&isClient=${isClient.toString()}`
        )
        const epiVideos = fetchEpiVideos.data as RecordedActivitiesApiResponse

        if (epiVideos.webinars.length === 0) {
          return {
            error: {
              status: 'FETCH_ERROR',
            } as FetchBaseQueryError,
          }
        }
        try {
          const joinedVideoIdParam =
            epiVideos.webinars.length > 0
              ? `&id=${epiVideos.webinars
                  .map((vid) => parseYoutubeId(vid.videoUrl))
                  .join(',')}`
              : ''

          const fetchVideosYouTubeData = (await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet${joinedVideoIdParam}&key=${
              getAppConfig().COMMON.YOUTUBE_API_KEY
            }`
          ).then((response) => response.json())) as YoutubeResponse

          return {
            data: {
              ...epiVideos,
              webinars: [
                ...fetchVideosYouTubeData.items.map((item, i) => ({
                  ...epiVideos.webinars[i],
                  duration: item.contentDetails.duration,
                  thumbnails: item.snippet.thumbnails,
                  id: item.id,
                })),
              ],
            },
          }
        } catch {
          return {
            data: {
              ...epiVideos,
            },
          }
        }
      },
    }),
  }),
})

export const { useGetActivitiesQuery, useGetRecordedActivititesQuery } = api

export default api
