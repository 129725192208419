import {
  Avatar,
  Box,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Content, EpiVideo } from 'types'
import { parseYoutubeDuration } from 'utils'
import { useEpiContent } from '@trr/app-shell-data'

const RecordedActivity = ({
  recording,
  isLast,
}: {
  recording: EpiVideo
  isLast: boolean
}) => {
  const content = useEpiContent<Content>()
  const videoThumbnail =
    recording.thumbnails &&
    Object.values(recording.thumbnails).sort((v) => v.height)[1]
  return (
    <CardActionArea
      data-testid="recordingLink"
      href={`${content.linkUrl}?tab=recorded&openVideo=${recording.id}`}
    >
      <ListItem divider={!isLast} alignItems="flex-start" className="item">
        {videoThumbnail && (
          <ListItemAvatar>
            <Avatar
              sx={{ width: 64, height: 64, mr: 2 }}
              variant="rounded"
              src={videoThumbnail.url}
              alt={recording.heading}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primaryTypographyProps={{
            component: 'h6',
            variant: 'subtitle1',
          }}
          primary={recording.heading}
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <>
              <Stack
                data-testid="recordingDurationIngress"
                direction="row"
                divider={
                  <Box fontSize={13} color="text.primary">
                    ●
                  </Box>
                }
                spacing={1}
              >
                {recording.duration && (
                  <Typography variant="body2" color="text.primary">
                    {parseYoutubeDuration(recording.duration)}
                  </Typography>
                )}
              </Stack>
              <Typography variant="body2">
                {content.recordedActivityText}
              </Typography>
            </>
          }
        />
      </ListItem>
    </CardActionArea>
  )
}

export default RecordedActivity
