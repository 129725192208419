import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Activities } from 'features/Activities'

import { Content } from 'types'

export const App = () => {
  const content = useEpiContent<Content>()
  return (
    <Card data-testid="activityWidget">
      <CardContent>
        <Typography variant="h6" component={'h2'}>
          {content.heading}
        </Typography>
        <Activities />
      </CardContent>
      <CardActions>
        <Button
          data-testid="allActivitiesLink"
          href={content.linkUrl}
          size="small"
          variant="text"
        >
          {content.linkText}
        </Button>
      </CardActions>
    </Card>
  )
}
